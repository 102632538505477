import React from 'react';

import styles from '../css/Portfolio.module.css';

import Circle from '../components/Circle';
import ProjectItem from '../components/ProjectItem';
import { useEffect, useState } from 'react';

// Import more components as necessary

export default function Portfolio() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const updateWindowWidth = () => {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', updateWindowWidth);

        return () => {
            window.removeEventListener('resize', updateWindowWidth);
        }
        
    },[])


    return (
        <>
            <div className={styles.container}>
                <div className={styles.header}>
                    <Circle size={windowWidth / (1261 / 300)} left="-10%" top="0%" hoverTop="15%" backgroundColor="#CDBEBA" zIndex="1"></Circle>
                    <Circle size={windowWidth / (1261 / 200)} left="0%" top="20%" hoverTop="15%" backgroundColor="#FDBEBA" zIndex="1">p</Circle>
                    <Circle size={windowWidth / (1261 / 200)} left="10%" top="10%" hoverTop="5%" backgroundColor="#C0E6FC" zIndex="2">o</Circle>
                    <Circle size={windowWidth / (1261 / 200)} left="20%" top="20%" hoverTop="15%" backgroundColor="#FEE5D9" zIndex="3">r</Circle>
                    <Circle size={windowWidth / (1261 / 250)} left="30%" top="25%" hoverTop="20%" backgroundColor="#D6FDD5" zIndex="3">t</Circle>
                    <Circle size={windowWidth / (1261 / 200)} left="40%" top="10%" hoverTop="5%" backgroundColor="#E8C1F4" zIndex="4">f</Circle>
                    <Circle size={windowWidth / (1261 / 250)} left="50%" top="5%" hoverTop="0%" backgroundColor="#FFBBFC" zIndex="1">o</Circle>
                    <Circle size={windowWidth / (1261 / 250)} left="60%" top="25%" hoverTop="20%" backgroundColor="#FFE0B3" zIndex="2">l</Circle>
                    <Circle size={windowWidth / (1261 / 150)} left="74%" top="35%" hoverTop="5%" backgroundColor="#FFDFD3" zIndex="4">i</Circle>
                    <Circle size={windowWidth / (1261 / 250)} left="80%" top="25%" hoverTop="20%" backgroundColor="#C5DED9" zIndex="3">o</Circle>
                    <Circle size={windowWidth / (1261 / 300)} left="90%" top="5%" hoverTop="0%" backgroundColor="#FFEEC1" zIndex="2"></Circle>
                    <Circle size={windowWidth / (1261 / 250)} left="95%" top="20%" hoverTop="15%" backgroundColor="#FFDDF4" zIndex="3"></Circle>
                </div>
                <div className={styles.projectList}>
                    <ProjectItem 
                        title="Simplify the Spreadsheet"
                        description="In my summer research internship with at Carnegie Mellon University's Human-Computer Interaction Institute, I worked with one other intern to design and develop and app that provides tools for researchers to look at new ways to input and visualize spreadsheet-esque data in non-spreadsheet ways in a fashion that would benefit makers of handmcrafted goods. I conducted a literature review of 11 peer-reviewed papers, attended different maker fares to understand the community better, got experience qualitatively coding interviews, and developed a prototype of the app using React Native and Expo."
                        imageSrc="/simplify-the-spreadsheet.png"
                        layout="left"
                        bgColor="#D5E1DD"
                        borderColor="#FFB6C1"  
                        skillList={["React", "React Native", "Expo", "REST APIs", "Google Firebase", "HTML", "CSS", "JavaScript"]}         
                    />

                    <ProjectItem 
                        title="RaspiShop.com"
                        description="After I took a liking with the RaspberryPi in high school I partnered with a friend to start selling RaspberryPi kits. I built out the ecommerce website using Wordpress and WooCommerce, and I also managed the customer support and order fulfillment. I interfaced often with headless RaspberryPi's to polish the software and educational tutorials that we sent with the kits. It was cool to see the project take on customers from 8 different countries including United States, Canada, Switzerland, Hungary, Slovakia, Austria, New Zealand, and Great Britain."
                        imageSrc="/raspiShop.png"
                        layout="right"
                        bgColor="#FAE3D9"
                        borderColor="#FFD1DC" 
                        skillList={["Project Management", "Customer Support", "Python", "Wordpress", "Eccommerce", "Networks"]}  
                    />

                    <ProjectItem 
                        title="TutorHHS.org"
                        description="Finding tutors for a certain class at my high-school had always been a difficult task for students and NHS administrators. During my senior year, I built this website to assist both parties in that process. TutorHHS.org is a live project that has served over 1,200 students and it projected to serve over 5,000 over the next 5 years. I integrated the maintainence into existing administrator workflows, so every year they can update the database simply by clearing a Google Sheet, and sending out a Google Form to tutors."
                        imageSrc="/tutorhhs.png"
                        layout="left"
                        bgColor="#D5E1DD"
                        borderColor="#FFB6C1"
                        skillList={["Express", "HTML", "CSS", "JavaScript", "Node.js", "Google Cloud Platform"]}  
                    />

                    <ProjectItem 
                        title="Autonomous Chess game recorder"
                        description="After watching Queen's Gambit, I became obsessed with chess. Playing online, I could analyze and study my mistakes easily. However, over-the-board I would get the tactile experience of moving the pieces, but I would have to manually record the game. I built a chessboard that would automatically record the moves of the game and broadcast them to a RaspberryPi. I used Hall-effect sensors under each tile accompanied by magnets embedded in each piece to get the data on where pieces were. Then, I came up with some boolean logic to determine which piece was moved where. I plan to make a web-app to make this project more useable!"
                        imageSrc="/chessboard.png"
                        layout="right"
                        bgColor="#FFD3B6"
                        borderColor="#D5E1DD"
                        skillList={["Python", "Circuit Design", "Raspberry Pi", "Soldering", "Chess"]}  
                    />

                    <ProjectItem 
                        title="Mind Manager"
                        description="During Steel City Hacks in 2020, I created a cross-platform mobile app built on KivyMD to help students manage school related work and prioritize mental health in the process. My team and I developed a machine learning model based off of student data from the UCI Machine Learning Repository to help students appropriately allocate study time. We experience sampled users as they opened the app to determine the fluctuations in their mental health, and would notify friends if the user was in a bad state. We ended getting an honorable mention!"
                        imageSrc="/mindmanager.png"
                        layout="right"
                        bgColor="#EFCFE7"
                        borderColor="#F5D5EA"
                        skillList={["Python", "sci-kit learn", "KivyMD", "Mobile App Development", "pandas", "NumPy", "Matplotlib"]}  
                    />

                    {/* ... more projects */}
                    <h2>This website is also a portfolio item. I built it using React.js - thanks for checking it out.</h2>
                </div>
            </div>
        </>
    );
}
