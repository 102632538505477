import styles from '../css/Introduction.module.css';

export default function Introduction({imageIndex}){
    const imageString  = "/woodstreet" + imageIndex + ".jpeg";

    return (
        <div className={styles.row}>
            <div className={styles.column}>
                <div className={styles.text}>
                    <h2>Hi, my name is Quentin.</h2>
                    <h2>BPhil in Computer Science @ Pitt</h2>
                    <h2>I'm interested in human-computer interaction, social impact, leadership, and tech policy.</h2>
                    <h2>Let's connect.</h2>
                </div>
            </div>

            <div className={styles.column}>
                <div className={styles.imageContainer}>
                    <img src={imageString}
                
                         alt="Quentin Romero Lauro posing in a blocky LED light tunnel"
                         className={styles.image}
                    />
                </div>
            </div>
        </div>
    );
}
